import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Col, Row } from "react-bootstrap";
import PaymentScreen from "../screens/PaymentScreen";

// const stripePromise = loadStripe(
//   "pk_live_51OIBhrH3KXjiwmSyNP39FazcirHorrTvPserdgbyk4uhMka2ZtrXVRhVZBvyfyJyBO7iLdJfgHwsAFLl5d84fKPR00fTiOr5GU"
// );
// ------------------------------------------------ TEST API --------------------------------------------------
const stripePromise = loadStripe(
  "pk_test_51OIBhrH3KXjiwmSyEHyWZVTcJTJA5xivApMn9vlXm8FUFsA6IcdzKQL7fX8fRqWxZuwSGgpt8wtgS27Rn9eN954D00P5BKyqMB"
);

const StripeContainer = () => {
  return (
    <>
      <Row className="h-100 w-75 m-auto justify-content-center align-items-center">
        <Col md={4} sm={4}>
          <Elements stripe={stripePromise}>
            <PaymentScreen />
          </Elements>
        </Col>
      </Row>
    </>
  );
};

export default StripeContainer;
