import {
  faCheck,
  faMagnifyingGlass,
  faMinus,
  faPenToSquare,
  faRotateLeft,
  faScrewdriverWrench,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  addTeamMember,
  addTip,
  deleteTeamMember,
  getAllMaids,
  getValetTeams,
  verifyPayment,
} from "../redux/Actions/maidAction";

const ValetTeam = ({ canRead }) => {
  const [teamToVerify, setTeamToVerify] = useState(null);
  const [localSuccess, setLocalSuccess] = useState(false);
  const [membersError, setMembersError] = useState(false);
  const [inactiveMemberError, setInactiveMemberError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [teamSearchQuerry, setTeamSearchQuerry] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [newTeam, setnewTeam] = useState([]);
  const [show, setShow] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { maids: allMaids } = useSelector((state) => state.maidsList);
  const {
    success,
    loading: paymentLoading,
    payouts,
  } = useSelector((state) => state.verifyPayment);
  const { valetTeams, loading, error } = useSelector(
    (state) => state.valetTeams
  );
  const valets = allMaids.filter((maid) => maid.type == "valet");
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;
  const model = userInfo?.model;

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setInactiveMemberError(false);
    setMembersError(false);
    setLocalSuccess(false);
  };

  const handleShow = () => setShow(true);

  const getTime = (timestampStr) => {
    const timestamp = new Date(timestampStr);
    if (!isNaN(timestamp.getTime())) {
      let hours = timestamp.getHours();
      const minutes = timestamp.getMinutes().toString().padStart(2, "0");
      const seconds = timestamp.getSeconds();
      const meridiem = hours >= 12 ? "PM" : "AM";

      hours = hours % 12 || 12;
      const formattedTime = `${hours}:${minutes} ${meridiem}`;

      return formattedTime;
    } else {
      return "Invalid Time";
    }
  };
  const isTimeWithinRange = (teamTime, startTime, endTime) => {
    if (teamTime !== "Invalid Time") {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);
      const [teamHour, teamMinute] = teamTime
        .split(" ")[0]
        .split(":")
        .map(Number);
      const teamMeridiem = teamTime.split(" ")[1];
      if (
        !isNaN(startHour) &&
        !isNaN(startMinute) &&
        !isNaN(endHour) &&
        !isNaN(endMinute)
      ) {
        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;
        let teamTotalMinutes = teamHour * 60 + teamMinute;
        if (teamMeridiem === "PM" && teamHour !== 12) {
          teamTotalMinutes += 12 * 60;
        }
        return (
          teamTotalMinutes >= startTotalMinutes &&
          teamTotalMinutes <= endTotalMinutes
        );
      }
    }
    return false;
  };
  const handleAddingMember = (valet, teamId) => {
    dispatch(addTeamMember(teamId, valet));
    // dispatch({ type: ADD_TEAM_MEMBER_RESET });
    setTeamToVerify((prevTeam) => ({
      ...prevTeam,
      teamMembers: [...prevTeam.teamMembers, valet],
    }));
  };
  const handleDelete = (valetTeamId, memberId) => {
    dispatch(deleteTeamMember(valetTeamId, memberId));
    setTeamToVerify((prevTeam) => ({
      ...prevTeam,
      teamMembers: prevTeam.teamMembers.filter(
        (member) => member._id !== memberId
      ),
    }));
  };
  const payoutVerficationB2c = (team) => {
    const teamId = team._id;
    const teamMembers = team.teamMembers;
    const hasInactiveMember = teamMembers.some(
      (member) => member.chargesStatus === false
    );
    const parentTransactionId = team.paymentIntentId
      ? team.paymentIntentId
      : null;
    const tipAmountPerMember = team.tip / team.teamMembers.length;
    const review = team.review;

    if (teamMembers.length > 0 && !hasInactiveMember) {
      dispatch(
        verifyPayment(
          hotelId,
          teamId,
          teamMembers,
          tipAmountPerMember,
          review,
          parentTransactionId
        )
      );
      setMembersError(false);
      setInactiveMemberError(false);
    } else {
      if (hasInactiveMember) {
        setInactiveMemberError(true);
      } else if (teamMembers.length === 0) {
        setMembersError(true);
      }
    }
    if (success) {
      setLocalSuccess(true);
    }
  };
  const payoutVerficationB2b = (team) => {
    const teamId = team._id;
    const teamMembers = team.teamMembers;
    const parentTransactionId = team.paymentIntentId
      ? team.paymentIntentId
      : null;
    const tipAmountPerMember = team.tip / team.teamMembers.length;
    const review = team.review;

    if (teamMembers.length > 0) {
      dispatch(
        verifyPayment(
          hotelId,
          teamId,
          teamMembers,
          tipAmountPerMember,
          review,
          parentTransactionId
        )
      );
      setMembersError(false);
    } else {
      setMembersError(true);
    }
    if (success) {
      setLocalSuccess(true);
    }
  };
  const handleVerify = (team) => {
    if (model.b2b) {
      payoutVerficationB2b(team);
    } else {
      payoutVerficationB2c(team);
    }
  };
  const handleFilter = () => {
    const filtered = valetTeams.filter((team) => {
      const teamStartDate = new Date(team.startDate);
      const teamEndDate = new Date(team.endDate);
      const teamTime = getTime(team.createdAt);
      const isTimeInRange =
        !startTime ||
        !endTime ||
        isTimeWithinRange(teamTime, startTime, endTime);
      const isDateInRange =
        (!startDate || teamStartDate >= new Date(startDate)) &&
        (!endDate || teamEndDate <= new Date(endDate));
      const isLocationMatch =
        !searchQuery ||
        team.location?.toLowerCase().includes(searchQuery.toLowerCase());

      return isDateInRange && isLocationMatch && isTimeInRange;
    });
    if (filtered.length === 0) {
      alert("No data found");
    } else {
      setFilteredTeams(filtered);
    }
  };
  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setSearchQuery("");
    setFilteredTeams([]);
  };
  useEffect(() => {
    dispatch(getValetTeams(hotelId));
    dispatch(getAllMaids(hotelId));
  }, [dispatch, success, filteredTeams]);
  useEffect(() => {
    if (success) {
      setLocalSuccess(true);
    }
  }, [success]);
  return (
    <>
      {teamToVerify && (
        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header className="bg-primary">
            <Modal.Title className="text-light mx-auto">
              Team Tip Verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row className="mt-3">
              <Col sm={6}>
                <Row className="">
                  <Col>
                    <span className="fw-bold d-inline">from:</span>
                    {new Date(teamToVerify.startDate).toLocaleDateString()}
                    <span className="fw-bold d-inline mx-1">to:</span>
                    {new Date(teamToVerify.endDate).toLocaleDateString()}
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <FontAwesomeIcon className="me-2" icon={faClock} />
                    {teamToVerify.createdAt && getTime(teamToVerify.createdAt)}
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <span className="fw-bold d-inline me-1">Tip amount:</span> $
                    {teamToVerify.tip}
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <span className="fw-bold d-inline me-1">Review:</span>
                    {teamToVerify.review}
                  </Col>
                </Row>
                <hr />
                <Row className="mt-3 justify-content-start">
                  <Col sm="auto">
                    <DropdownButton
                      disabled={teamToVerify.verified}
                      id="dropdown-item-button"
                      title="Add member"
                      className=""
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search names..."
                        value={teamSearchQuerry}
                        onChange={(e) => setTeamSearchQuerry(e.target.value)}
                        className="mb-2 rounded-0 bg-light"
                      />
                      {valets
                        .filter((valet) =>
                          valet.serviceName
                            .toLowerCase()
                            .includes(teamSearchQuerry.toLowerCase())
                        )
                        .map((valet, index) => (
                          <Dropdown.Item
                            as="button"
                            key={index}
                            onClick={() => {
                              handleAddingMember(valet, teamToVerify._id);
                            }}
                          >
                            {valet.serviceName}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="ps-5 pe-3">
                {teamToVerify.teamMembers.map((member, index) => (
                  <Row
                    key={index}
                    className="bg-primary mb-2 text-light p-1 rounded-4 align-items-center"
                  >
                    {!model.b2b && (
                      <Col sm={1} className="pe-0 m-0">
                        <div
                          className={
                            member.chargesStatus
                              ? "rounded-circle bg-success display-flex"
                              : "rounded-circle bg-danger display-flex"
                          }
                          style={{ width: "8px", height: "8px" }}
                        ></div>
                      </Col>
                    )}
                    <Col sm="auto">{member.serviceName}</Col>
                    <Col sm="auto" className="ms-auto">
                      <Button
                        className="m-0 p-0"
                        disabled={teamToVerify.verified}
                      >
                        <FontAwesomeIcon
                          onClick={() => {
                            handleDelete(teamToVerify._id, member._id);
                          }}
                          className="text-danger"
                          icon={faMinus}
                        />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row className="mt-4 px-3">
              {membersError && (
                <span
                  className="text-danger text-center border border-1 border-danger rounded-2 p-1 mb-1"
                  style={{ fontSize: "14px" }}
                >
                  Please select atleast one team member
                </span>
              )}
              {inactiveMemberError && (
                <span
                  className="text-danger text-center border border-1 border-danger rounded-2 p-1"
                  style={{ fontSize: "14px" }}
                >
                  Make sure all team member's account status is green
                </span>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={teamToVerify.verified || paymentLoading || localSuccess}
              variant="success"
              onClick={() => {
                teamToVerify.verified || localSuccess
                  ? handleClose()
                  : handleVerify(teamToVerify);
              }}
            >
              {paymentLoading ? (
                "Verifying..."
              ) : localSuccess ? (
                <span>
                  <FontAwesomeIcon icon={faCheck} className="me-1" />
                  Verified
                </span>
              ) : (
                "Verify"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* --------------------------------------------------- FILTERS -------------------------------------------------- */}
      <Row className="mb-5 mt-1 justify-content-center align-items-center ">
        <Col
          xs="auto"
          className="border border-1 border-secondary rounded-4 text-center mx-1"
        >
          <FloatingLabel label="Start Date">
            <Form.Control
              type="date"
              className="border-0"
              placeholder="Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col
          xs="auto"
          className="border border-1 border-secondary rounded-4 text-center mx-1"
        >
          <FloatingLabel label="End Date">
            <Form.Control
              className="border-0"
              type="date"
              placeholder="Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col
          xs="auto"
          className="border border-1 border-secondary rounded-4 text-center mx-1"
        >
          <FloatingLabel label="Start Time">
            <Form.Control
              className="border-0"
              type="time"
              placeholder="Time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col
          xs="auto"
          className="border border-1 border-secondary rounded-4 text-center mx-1"
        >
          <FloatingLabel label="End Time">
            <Form.Control
              className="border-0"
              type="time"
              placeholder="Time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col
          xs="auto"
          className="border border-1 border-secondary rounded-4 text-center mx-1"
        >
          <FloatingLabel label="Location">
            <Form.Control
              className="border-0"
              type="text"
              placeholder="Client Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col xs="auto">
          <Button
            className="p-3 rounded-4 mx-1"
            variant="outline-primary"
            onClick={clearFilters}
          >
            <FontAwesomeIcon icon={faRotateLeft} className="fs-5" />
          </Button>
          <Button
            className="p-3 rounded-4 mx-1"
            variant="outline-primary"
            onClick={handleFilter}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="fs-5" />
          </Button>
        </Col>
      </Row>
      {valetTeams.length > 0 ? (
        <Row className="ps-2">
          <Col>
            <Row>
              <ListGroup
                variant="flush"
                className=" p-0 rounded-3 border border-1"
              >
                <ListGroup.Item className="bg-primary mb-3">
                  <Row>
                    <Col md={2} className="text-light fs-5">
                      Location
                    </Col>
                    <Col md={2} className="text-light fs-5">
                      Time
                    </Col>
                    <Col md={3} className="text-light fs-5">
                      Date
                    </Col>
                    <Col md={1} className="text-light fs-5">
                      Tip
                    </Col>
                    <Col md={2} className="text-light fs-5">
                      Review
                    </Col>
                  </Row>
                </ListGroup.Item>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message>{error}</Message>
                ) : (
                  <>
                    <>
                      {(filteredTeams.length > 0 ? filteredTeams : valetTeams)
                        .filter((team) => !team.verified)
                        .slice()
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((team, index) => (
                          <ListGroup.Item className="py-0" key={index}>
                            <Row className="align-items-center">
                              <Col md={2} className="text-muted ">
                                {team.location == "$session.params.location"
                                  ? "Not Defined"
                                  : team.location}
                              </Col>
                              <Col md={2} className="text-muted fst-italic">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="me-1"
                                />
                                {team.createdAt && getTime(team.createdAt)}
                              </Col>
                              <Col md={3}>
                                <span className="text-success mx-1">
                                  {new Date(
                                    team.startDate
                                  ).toLocaleDateString()}
                                </span>
                                to
                                <span className="text-success mx-1">
                                  {new Date(team.endDate).toLocaleDateString()}
                                </span>
                              </Col>
                              <Col md={1}>${team.tip}</Col>
                              <Col md={2}>{team.review}</Col>
                              <Col md="auto" className="ms-auto">
                                {team.verified ? (
                                  <Button
                                    disabled={canRead}
                                    variant="outline-success"
                                    className="mx-2"
                                    onClick={() => {
                                      setShow(true);
                                      setTeamToVerify(team);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faCheck}
                                    />{" "}
                                    Verified
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={canRead}
                                    variant="outline-primary"
                                    className="mx-2"
                                    onClick={() => {
                                      setShow(true);
                                      setTeamToVerify(team);
                                      setMembersError(false);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faPenToSquare}
                                    />{" "}
                                    Verify
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <hr />
                          </ListGroup.Item>
                        ))}
                    </>
                  </>
                )}
              </ListGroup>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center align-items-center vh-100">
          <h5 className="text-center">No records found</h5>
        </Row>
      )}
    </>
  );
};

export default ValetTeam;
