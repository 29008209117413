import React, { useEffect, useMemo, useState } from "react";
import { Box, Switch, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faDownload,
  faDownLong,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";
import { Badge, Button, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  getAdminXReports,
  getAdminXReportsByDate,
} from "../../redux/Actions/paymentAction";
const { RangePicker } = DatePicker;

const AdminXReports = () => {
  const [fetchAllPayments, setFetchAllPayments] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const { user } = useSelector((state) => state.userDetails);
  const adminXId = user && user._id;
  const { loading, payments, totalPayments, currentPage } = useSelector(
    (state) => state.adminXReports
  );
  const {
    loading: loadingByDate,
    payments: paymentsByDate,
    totalPayments: totalPaymentsByDate,
  } = useSelector((state) => state.adminXReportsByDate);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const dispatch = useDispatch();

  const processedPayments = useMemo(
    () =>
      payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    [payments]
  );
  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
    const [start, end] = dates || [null, null];
    if (start && end) {
      dispatch(getAdminXReportsByDate(start.toISOString(), end.toISOString()));
    }
  };

  const averageTip = useMemo(
    () =>
      payments.reduce((acc, curr) => acc + curr.tipAmount, 0) / payments.length,
    [payments]
  );

  const totalTip = useMemo(
    () => payments.reduce((acc, curr) => acc + curr.tipAmount, 0),
    [payments]
  );

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "rowNumber",
        size: 50,
        Cell: ({ row }) => (
          <span className="fw-semibold">{row.index + 1}.</span>
        ),
        enableGrouping: false,
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        size: 70,
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
      },
      {
        header: "Transaction ID",
        accessorKey: "paymentIntentId",
        size: 250,
        Cell: ({ cell }) => (
          <span className="text-muted fst-italic">{cell.getValue()}</span>
        ),
      },
      {
        header: "Client Name",
        accessorKey: "clientName",
        Cell: ({ cell }) => (
          <span className="fw-semibold">{cell.getValue()}</span>
        ),
      },
      {
        header: "Tip Type",
        accessorKey: "tipType",
        Cell: ({ cell }) =>
          cell.getValue() === "teamTip" ? "Pool Tip" : "Direct Tip",
      },
      {
        header: "Service",
        accessorKey: "service",
        Cell: ({ cell }) =>
          cell.getValue() === "maid"
            ? // <CellBadge label={"Attendants"} />
              "Room Attendant"
            : cell.getValue() === "valet"
            ? // <CellBadge label={"Valet"} />
              "Valet"
            : cell.getValue() === "bellman"
            ? // <CellBadge label={"Bell Staff"} />
              "Bell Staff"
            : null,
      },
      {
        header: "Recipient Account",
        accessorKey: "connectAccount",
        enableHiding: true,
        Cell: ({ cell }) => (
          <span className="text-muted fst-italic">{cell.getValue()}</span>
        ),
      },
      {
        header: "Tip Amount",
        accessorKey: "tipAmount",
        size: 100,
        Cell: ({ cell }) => (
          <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
        ),
        aggregationFn: "sum",
        AggregatedCell: ({ cell }) => (
          <Box sx={{ color: "success.main", fontWeight: "bold" }}>
            Total tips:{" "}
            {cell.getValue()?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Box>
        ),
      },
      {
        header: "Commission on Tip",
        accessorKey: "commissionCharged",
        size: 100,
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
          ) : (
            0
          ),
      },
      {
        header: "Processing Fee",
        accessorKey: "processingFee",
        size: 100,
        Cell: ({ cell }) => (
          <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
        ),
      },
      {
        header: "Total Amount",
        accessorKey: "amount",
        size: 100,
        Cell: ({ cell }) => (
          <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
        ),
        aggregationFn: "mean",
        AggregatedCell: ({ cell }) => (
          <Box sx={{ color: "info.main", fontWeight: "bold" }}>
            Average tip:{" "}
            {cell.getValue()?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Box>
        ),
      },
      {
        header: "Payment Method",
        accessorKey: "paymentMethod",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === "apple_pay" ? (
              <span className="text-muted">
                <FontAwesomeIcon icon={faApple} className="mx-1" />
                Pay
              </span>
            ) : cell.getValue() === "google_pay" ? (
              <span className="text-muted">
                <FontAwesomeIcon icon={faGoogle} className="mx-1" />
                Pay
              </span>
            ) : (
              <span className="text-muted">
                <FontAwesomeIcon icon={faCreditCard} className="mx-1" />
                Card
              </span>
            )}
          </Box>
        ),
      },
      {
        header: "Tip",
        accessorKey: "selection",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value === true
            ? "Suggested"
            : value === false
            ? "Custom"
            : "NA";
        },
        enableHiding: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 100,
        Cell: ({ row }) => {
          const status = row.original.status?.toLowerCase();
          const badgeTypes = {
            refunded: "info",
            disputed: "danger",
            success: "success",
          };

          return (
            <Badge pill bg={badgeTypes[status] || "secondary"}>
              {status
                ? status.charAt(0).toUpperCase() + status.slice(1)
                : "Unknown"}
            </Badge>
          );
        },
      },
      {
        header: "Location",
        accessorKey: "clientLocation",
        enableHiding: true,
      },
      {
        header: "Sub Location",
        accessorKey: "subLocation",
        enableHiding: true,
      },
      {
        header: "Stripe Fee",
        accessorKey: "stripeFee",
        Cell: ({ cell }) => (
          <Box sx={{ color: "warning.main" }}>$ {cell.getValue()}</Box>
        ),
        enableHiding: true,
      },
      {
        header: "Time",
        accessorKey: "time",
        Cell: ({ cell }) =>
          new Date(cell.getValue()).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        enableHiding: true,
      },
      {
        header: "Card Last 4",
        accessorKey: "last4",
        Cell: ({ cell }) => (
          <span className="text-muted fw-semibold">{cell.getValue()}</span>
        ),
        enableHiding: true,
      },
      {
        header: "Rating",
        accessorKey: "rating",
        Cell: ({ cell }) => (
          <span className="text-warning">{cell.getValue()}</span>
        ),
        enableHiding: true,
      },
    ],
    [totalTip]
  );

  const table = useMaterialReactTable({
    columns,
    enableColumnOrdering: true,
    // enableRowPinning: true,
    data: dateRange[0] && dateRange[1] ? paymentsByDate : processedPayments,
    state: {
      pagination,
      isLoading: dateRange[0] && dateRange[1] ? loadingByDate : loading,
    },
    onPaginationChange: setPagination,
    rowCount:
      dateRange[0] && dateRange[1] ? totalPaymentsByDate : totalPayments,
    manualPagination: true,
    enablePagination: !fetchAllPayments,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: true,
    enableExport: true,
    initialState: {
      density: "compact",
      columnVisibility: {
        clientLocation: false,
        subLocation: false,
        // stripeFee: false,
        // status: false,
        connectAccount: false,
        service: false,
        // tipType: false,
      },
      expanded: true,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: 700,
        overflowX: "auto",
        overflowY: "auto",
        padding: 2,
        marginBottom: 2,
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": { width: "12px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "18px",
        },
        "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#f5f5f5",
        color: "#333",
        padding: "10px",
        textAlign: "center",
      },
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <Switch
          checked={fetchAllPayments}
          onChange={(e) => setFetchAllPayments(e.target.checked)}
          color="primary"
        />
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          All Transactions
        </Typography>
        <RangePicker
          className="mx-2"
          value={dateRange}
          onChange={handleDateRangeChange}
          placeholder={["Start Date", "End Date"]}
        />
        <Button
          onClick={() => {
            const csvRows = [
              columns.map((col) => col.header).join(","),
              ...processedPayments.map((row) =>
                columns
                  .map((col) => JSON.stringify(row[col.accessorKey] || ""))
                  .join(",")
              ),
            ];
            const csvContent = csvRows.join("\n");
            const blob = new Blob([csvContent], {
              type: "text/csv;charset=utf-8;",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "tipkit-payments.csv";
            link.click();
          }}
          variant="outline-secondary"
          className="border-0 fs-7"
        >
          <FontAwesomeIcon icon={faDownload} /> Export CSV
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    if (fetchAllPayments) {
      dispatch(getAdminXReports(adminXId, 1, "all"));
    } else {
      dispatch(
        getAdminXReports(
          adminXId,
          pagination.pageIndex + 1,
          pagination.pageSize
        )
      );
    }
  }, [fetchAllPayments, user, pagination.pageIndex, pagination.pageSize]);

  return (
    <Row className="mt-5 p-3">
      <MaterialReactTable table={table} />
    </Row>
  );
};

export default AdminXReports;
